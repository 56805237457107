import { keyframes } from '@mui/system';
import { Badge, Button, DialogContent, styled, Typography } from '@mui/material';
import { green } from '@mui/material/colors';

//rewrite and create one common component for onlineUsers and onlineDevices
export const ButtonStyled = styled(Button)`
  min-width: 0;
  font-size: 15px;

  & svg {
    margin-right: ${(props) => props.theme.spacing(0.5)};
  }
`;

export const BadgeStyled = styled(Badge)<{ $offline?: boolean }>`
  & .MuiBadge-dot {
    background-color: ${(props) => (props.$offline ? props.theme.palette.grey[400] : green[400])};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    bottom: 2px;
    left: 4px;
  }
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 350px;
`;

export const OnlineIndicator = styled('div')<{ away?: boolean }>`
  background-color: ${(props) => (props.away ? props.theme.palette.warning.main : props.theme.palette.success.main)};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
export const TypographyStyled = styled(Typography)`
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const blinkAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
export const LoadingText = styled('div')`
  & .dot {
    opacity: 0;
    animation: ${blinkAnimation} 2s infinite linear;
  }

  & .dot:nth-child(1) {
    animation-delay: 0.2s;
  }

  & .dot:nth-child(2) {
    animation-delay: 0.4s;
  }

  & .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
`;
