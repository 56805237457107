import { FC } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';

import { Environment } from '@playq/octopus-common';

import { IPromotionConfirmProps } from '/shared/PromotionConfirmDialog/types';
import { sandboxColor, liveColor } from '/shared/Promotions';

import { ButtonWrapper, ExtraText, RootCancelButton, RootConfirmButton } from './styles';

export const PromotionConfirmContent: FC<IPromotionConfirmProps> = ({ confirm, closeDialog, statusData }) => {
  const { status, env, extraInfo } = statusData;

  const action = status ? 'demote from' : 'promote to';
  const title = `Confirm ${status ? 'demoting' : 'promoting'}`;

  const confirmButtonTitle = status ? 'demote' : 'promote';
  const confirmButtonColor = env === Environment.Live ? liveColor : sandboxColor;

  const subHeaderNode: JSX.Element = (
    <>
      <Typography variant='body1' component='div'>
        Please, be sure that you really want {confirmButtonTitle} the entity.
      </Typography>
      <Typography variant='body1' component='div'>
        This entity {!status && `and its data `}
        {status ? 'will not be' : 'will be'} accessible on {env.toLowerCase()} environment
        {status && ` also some of it's data may be lost`}.
      </Typography>
    </>
  );

  return (
    <Card>
      <CardHeader title={title} subheader={subHeaderNode} />
      <CardContent data-testid='confirmPromote-content'>
        {extraInfo?.showOnDemote && status && (
          <ExtraText color={confirmButtonColor} variant='overline' component='div'>
            {extraInfo?.showOnDemote}
          </ExtraText>
        )}
        {extraInfo?.showOnPromote && !status && (
          <ExtraText color={confirmButtonColor} variant='overline' component='div'>
            {extraInfo?.showOnPromote}
          </ExtraText>
        )}
        <ButtonWrapper>
          <RootConfirmButton fullWidth={true} variant={'contained'} size={'medium'} env={env} onClick={confirm}>
            {action} {env}
          </RootConfirmButton>
          <RootCancelButton color='inherit' size={'medium'} env={env} onClick={closeDialog}>
            Cancel
          </RootCancelButton>
        </ButtonWrapper>
      </CardContent>
    </Card>
  );
};
