import { FC, forwardRef, ReactElement, ReactNode } from 'react';
import { AppBar, Box, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';

import { Wrapper } from './styles';

interface IPreviewEntityProps {
  content: ReactNode;
  handleClose: () => void;
  open: boolean;
  title: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Transition = forwardRef<unknown, TransitionProps & { children: ReactElement<any, any> }>((props, ref) => (
  <Slide direction='up' ref={ref} {...props}>
    {props.children}
  </Slide>
));

export const PreviewEntity: FC<IPreviewEntityProps> = (props) => {
  const { content, handleClose, open, title } = props;

  return (
    <Dialog fullScreen={true} open={open} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'unset' }}>
        <Toolbar sx={{ minHeight: '47px' }}>
          <Wrapper>
            <Typography variant='h6'>{title}</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='h6'>Close</Typography>
              <IconButton
                edge='end'
                data-testid='close-preview-icon'
                color='inherit'
                onClick={handleClose}
                size='large'
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Wrapper>
        </Toolbar>
      </AppBar>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};
