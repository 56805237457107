import { FC } from 'react';
import { styled } from '@mui/material';
import { InfoOutlined as MuiInfoOutlinedIcon, Warning as MuiWarningIcon } from '@mui/icons-material';
import { orange, red } from '@mui/material/colors';

import { Environment } from '@playq/octopus-common';

import { IThemeDependentStyledComponentProps } from '/common/models';
import { TextAnimation as SharedTextAnimation } from '/shared/TextAnimation';
import { StatusButton } from '/shared/Environment';

export const Label = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  &:last-child {
    margin-top: 3px;
  }

  &:first-child:last-child {
    margin: 0.5rem 0;
  }
`;

export const sandboxColor = orange[500];
export const liveColor = red[500];

const statusIconStyles = `margin-left: 5px;
  font-size: 19px;
`;

export const WarningIcon = styled(MuiWarningIcon)`
  ${statusIconStyles};
  margin-bottom: -3px;
`;

export const InfoIcon = styled(MuiInfoOutlinedIcon)`
  ${statusIconStyles};
  color: ${orange[700]};
`;

interface ITextAnimationStyled {
  env: Environment;
  text: string;
}

export const TextAnimation: FC<ITextAnimationStyled> = ({ env, text }) => (
  <SharedTextAnimation color={env === Environment.Sandbox ? sandboxColor : liveColor}>{text}</SharedTextAnimation>
);

export const PreviewButton = styled(StatusButton)<IThemeDependentStyledComponentProps>`
  color: ${({ $themePalette }: IThemeDependentStyledComponentProps) => $themePalette?.text.primary};
`;

export const EnvironmentsWrapper = styled('div')`
  min-width: 550px;

  .env-form {
    .demote {
      margin-left: auto;
    }

    &:not(:last-of-type) {
      margin-bottom: ${(props) => props.theme.spacing(2)};
    }
  }
`;

export const ErrorText = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)};
  margin: 0 auto 0 ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.palette.error.main};
`;

export const Wrapper = styled('div')`
  width: fit-content;
`;

export const ControlBarWrapper = styled('div')`
  margin-right: ${(props) => props.theme.spacing(1)};
`;

export const Title = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
