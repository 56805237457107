import { Button, styled, Typography } from '@mui/material';
import { orange, red } from '@mui/material/colors';

import { Environment } from '@playq/octopus-common';

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RootConfirmButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'env',
})<{ env: Environment }>`
  max-width: 50%;
  color: white;
  background-color: ${({ env, theme }) =>
    env === Environment.Live ? theme.palette.promotion.live : theme.palette.promotion.sandbox};

  &:hover {
    background: ${({ theme, env }) => {
      if (theme.palette.mode === 'dark') {
        return env === Environment.Live ? red[300] : orange[300];
      }
      return env === Environment.Live ? red[800] : orange[800];
    }};
    color: white;
  }
`;

export const RootCancelButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'env',
})<{ env: Environment }>`
  color: ${({ env, theme }) =>
    env === Environment.Live ? theme.palette.promotion.live : theme.palette.promotion.sandbox};
`;

export const ExtraText = styled(Typography)`
  margin-bottom: 1em;
  font-size: 1em;
  line-height: 1.5em;
` as typeof Typography;
