import { Dialog } from '@mui/material';
import { FC, SyntheticEvent } from 'react';

import { PromotionConfirmContent } from './PromotionConfirmContent';
import { IPromotionConfirmProps } from './types';

export const PromotionConfirmDialog: FC<Omit<IPromotionConfirmProps, 'confirm'>> = ({ closeDialog, statusData }) => {
  const { status, env, setEnvStatus } = statusData;

  const confirm = (e?: SyntheticEvent) => {
    e?.stopPropagation();
    setEnvStatus(env, status);
    closeDialog();
  };

  return (
    <Dialog open={true} onClose={closeDialog} onKeyUp={confirm} fullWidth={true} maxWidth='sm'>
      <PromotionConfirmContent confirm={confirm} closeDialog={closeDialog} statusData={statusData} />
    </Dialog>
  );
};
